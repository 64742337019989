export const environment = {
    version : require('../../package.json').version,
    production: true,
    /*firebaseConfig: {
        apiKey: "AIzaSyDDCZ0iUQOAXkgphvcNKxzWmL_MPnSnnCQ",
        authDomain: "facturasend.firebaseapp.com",
        projectId: "facturasend",
        storageBucket: "facturasend.appspot.com",
        messagingSenderId: "183233297538",
        appId: "1:183233297538:web:af0c15ab1ed9a33054889b",
        measurementId: "G-TRZ11EFG8B"
    },*/
    keycloakConfig : {
        clientId : 'KC_REPLACE_CLIENT_ID',
        realm : 'KC_REPLACE_REALM',
        url : 'KC_REPLACE_AUTH_URL'
    },
    url: 'FS_REPLACE_API_URL',
    urlWSS: 'FS_REPLACE_WSS_URL',
    urlPWA: 'FS_REPLACE_PWA_URL',
    publicAWSHost : 'FS_REPLACE_AWS_URL',
    useCaptcha : false
};
